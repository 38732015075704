<template>
  <!-- sidebar menu area start -->
  <div class="sidebar-menu">
    <div class="sidebar-header">
      <div class="logo">
        <a href="index.html"><img src="../../assets/img/brand-logo-2.png" alt="logo"></a>
      </div>
    </div>
    <div class="main-menu">
      <div class="menu-inner">
        <nav>
          <ul class="metismenu" id="menu">
            <li>
              <router-link to="/dashboard"><i class="ti-user"></i> <span>home</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/users"><i class="ti-receipt"></i> <span>users</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/staff"><i class="ti-receipt"></i> <span>staff</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/contacts"><i class="ti-receipt"></i> <span>contacts</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/finance"><i class="ti-receipt"></i> <span>finance</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/our-hub"><i class="ti-receipt"></i> <span>the hub</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/quota"><i class="ti-receipt"></i> <span>Quota</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/qr"><i class="ti-receipt"></i> <span>qr code</span></router-link>
            </li>
            <li>
              <router-link to="/dashboard/enrollers"><i class="ti-receipt"></i> <span>enrollers</span></router-link>
            </li>

            <li><a @click.prevent="logout" href="#"><i class="ti-power-off"></i> <span>logout</span></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <!-- sidebar menu area end -->
</template>
<script>
import logOut from "@/utils/logout";
export default {
  data() {
    return {
      url: process.env.BASE_URL
    }
  },

  methods: {
    logout() {
      logOut(this.$store, this.$swal);
      console.log("base", this.url);
      window.location.href = this.url;
      // this.$store.dispatch("logout").then((data) => {
      //   if (data) {
      //     this.alertSuccess("Logout Successful");
      //     this.$router.push("/login");
      //   } else {
      //     console.log("logout unsuccessful");
      //   }
      // });
      // this.$store.commit("clearToken");
      // this.$store.commit("clearUser");
      // localStorage.removeItem("token");
      // localStorage.removeItem("user");
      // this.alertSuccess("Logout Successful");
      // this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.router-link-exact-active {
  color: #24b99a !important;
  /*border-bottom: 4px solid #24b99a;*/
}
</style>