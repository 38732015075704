<template>
  <div>
    <span id="navigation" style="display: none;"></span>
    <!-- <div id="preloader">
      <div class="loader"></div>
    </div> -->
    <div class="page-container">
      <TheSidebar />
      <div class="main-content">
        <TheHeader />
        <PageTitleArea />
        <div class="main-content-inner">
          <router-view />
        </div>
      </div>
      <TheFooter />
    </div>
    <VueInjectJs src="/dashboard/js/jquery-2.2.4.min.js" />
    <VueInjectJs src="/dashboard/js/popper.min.js" />
    <VueInjectJs src="/dashboard/js/bootstrap.min.js" />
    <VueInjectJs src="/dashboard/js/owl.carousel.min.js" />
    <VueInjectJs src="/dashboard/js/metisMenu.min.js" />
    <VueInjectJs src="/dashboard/js/jquery.slimscroll.min.js" />
    <VueInjectJs src="/dashboard/js/jquery.slicknav.min.js" />
    <VueInjectJs src="/dashboard/js/plugins.js" />
    <VueInjectJs src="/dashboard/js/scripts.js" />
  </div>
</template>

<script>
import $ from "jquery";
import VueInjectJs from "vue-inject-js";
import TheHeader from "@/components/Dashboard/TheHeader.vue";
import TheSidebar from "@/components/Dashboard/TheSidebar.vue";
import TheFooter from "@/components/Dashboard/TheFooter.vue";
import PageTitleArea from "../components/Dashboard/PageTitleArea.vue";
export default {
  components: {
    VueInjectJs,
    TheHeader,
    PageTitleArea,
    TheSidebar,
    TheFooter
  },

  watch: {
    $route() {
      $('.page-container').addClass('sbar_collapsed');
    }
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.$store.dispatch("admin/getStaff", user.id)
      .then((data) => {
          console.log("staff data: ", data.data);
          console.log("data obj: ", data.data[0]);
          if (data.data[0] !== undefined) {
            this.$store.commit("admin/SET_SINGLE_STAFF", data.data[0]);
            localStorage.setItem("staff", JSON.stringify(data.data[0]));
          } else {
            this.$store.commit("admin/SET_SINGLE_STAFF", {})
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }
}
</script>

<style>
@import "../assets/dashboard/css/bootstrap.min.css";
@import "../assets/dashboard/css/font-awesome.min.css";
@import "../assets/dashboard/css/themify-icons.css";
@import "../assets/dashboard/css/metisMenu.css";
/*@import "../assets/dashboard/css/owl.carousel.min.css";*/
@import "../assets/dashboard/css/slicknav.min.css";
@import "../assets/dashboard/css/typography.css";
@import "../assets/dashboard/css/default-css.css";
@import "../assets/dashboard/css/styles.css";
@import "../assets/dashboard/css/responsive.css";
</style>