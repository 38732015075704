<template>
  <!-- page title area start -->
  <div class="page-title-area">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="breadcrumbs-area clearfix">
          <h4 class="page-title pull-left">Dashboard</h4>
          <!-- <ul class="breadcrumbs pull-left">
            <li><a href="index.html">Home</a></li>
            <li><span>Dashboard</span></li>
          </ul> -->
        </div>
      </div>
      <div class="col-sm-6 clearfix">
        <div class="user-profile pull-right">
          <img class="avatar user-thumb" src="../../assets/images/author/avatar.png" alt="avatar">
          <h4 class="user-name dropdown-toggle" data-toggle="dropdown">{{ user.name }} <i class="fa fa-angle-down"></i></h4>
          <div class="dropdown-menu">
            <!-- <a class="dropdown-item" href="#">Message</a>
            <a class="dropdown-item" href="#">Settings</a> -->
            <a @click.prevent="logout" class="dropdown-item" href="#">Log Out</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- page title area end -->
</template>
<script>
import logOut from "@/utils/logout";
export default {
  data() {
  	return {
  		user: JSON.parse(localStorage.getItem("user")),
  		url: process.env.BASE_URL
  	}
  },

  methods: {
  	logout() {
      logOut(this.$store, this.$swal);
      console.log("base", this.url);
      window.location.href = this.url;
  	}
  }
}
</script>

<style scoped>
	.user-profile {
		background: #24b99a;
	}

	.page-title-area:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 4px;
    height: 36px;
    background: #24b99a;
    transform: translateY(-50%);
}
</style>